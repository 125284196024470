import { UserHelpFormModel } from '@/schemas/help/help';
import { MutationSaveUserHelpArgs } from '@ecdlink/graphql';

export const mapUserHelpDto = (
  userHelpFormData: UserHelpFormModel
): MutationSaveUserHelpArgs => {
  return {
    userHelp: {
      CellNumber: userHelpFormData.userHelp.CellNumber,
      ContactPreference: userHelpFormData.userHelp.ContactPreference,
      Description: userHelpFormData.userHelp.Description,
      Email: userHelpFormData.userHelp.Email,
      Id: userHelpFormData.userHelp.Id,
      InsertedDate: new Date(
        userHelpFormData.userHelp.InsertedDate
      ).toISOString(),
      IsLoggedIn: userHelpFormData.userHelp.IsLoggedIn,
      Subject: userHelpFormData.userHelp.Subject,
      TenantId: userHelpFormData.userHelp.TenantId,
      UpdatedBy: userHelpFormData.userHelp.UpdatedBy,
      UpdatedDate: new Date(
        userHelpFormData.userHelp.UpdatedDate
      ).toISOString(),
      Url: userHelpFormData.userHelp.Url,
      UserId: userHelpFormData.userHelp.UserId,
      IsActive: true,
    },
    screenshotBase64String: userHelpFormData.screenshotBase64String,
    fileName: userHelpFormData.fileName,
    fileType: userHelpFormData.fileType!,
  };
};
